*,
*:before,
*:after {
  box-sizing: border-box;
}

.strip-top {
  width: calc(50vw + 90px);
  transform: skewX(20deg) translateX(160px);
}

.strip-bottom {
  width: calc(50vw + 40px);
  transform: skewX(-15deg) translateX(90px);
}

.center {
  display: flex;
  width: 100%;
}

.card {
  width: inherit;
  flex-grow: 1;
  height: 190px;
}

.front,
.back {
  width: inherit;
  height: inherit;
  border-radius: 15px;
  color: #fff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.3);
  backface-visibility: hidden;
  background-image: linear-gradient(to right, #111, #49708d);
  overflow: hidden;
}

.front {
  transform: translateZ(0);
}

.strip-bottom,
.strip-top {
  position: absolute;
  right: 0;
  height: inherit;
  background-image: linear-gradient(to bottom, #3a9bc2, #0d4f6d);
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.5);
}

.strip-bottom {
  width: 200px;
  transform: skewX(-15deg) translateX(50px);
}

.strip-top {
  width: 180px;
  transform: skewX(20deg) translateX(50px);
}

.investor {
  width: fit-content;
  position: relative;
  top: 20px;
  left: 25px;
  text-transform: uppercase;
}

.chip {
  position: relative;
  top: 30px;
  left: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 40px;
  border-radius: 5px;
  background-image: linear-gradient(to bottom left, #ffecc7, #d0b978);
  overflow: hidden;
}

.chip-line {
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #333;

  &:nth-child(1) {
    top: 13px;
  }

  &:nth-child(2) {
    top: 20px;
  }

  &:nth-child(3) {
    top: 28px;
  }

  &:nth-child(4) {
    left: 25px;
    width: 1px;
    height: 50px;
  }
}

.chip-main {
  width: 20px;
  height: 25px;
  border: 1px solid #333;
  border-radius: 3px;
  background-image: linear-gradient(to bottom left, #efdbab, #e1cb94);
  z-index: 1;
}

.wave {
  position: relative;
  top: -10px;
  left: -65px;
}

.card-number {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 25px 10px;
  font-size: 23px;
  font-family: "cc font", monospace;
}

.card-holder {
  width: fit-content;
  margin: 10px 25px;
  text-transform: uppercase;
  font-family: "cc font", monospace;
}

.master {
  position: absolute;
  right: 20px;
  bottom: 15px;
  display: flex;
}

.card {
  perspective: 1000;

  &:hover .flip {
    transform: rotateY(180deg);
  }
}
