*,
*:before,
*:after {
  box-sizing: border-box;
}

.center {
  display: flex;
  width: 100%;
}

.card {
  width: inherit;
  flex-grow: 1;
  height: 125px;
  perspective: 1000;
}

.front {
  width: inherit;
  height: inherit;
  color: #fff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.3);
  backface-visibility: hidden;
  background-image: linear-gradient(to right, #111, #49708d);
  overflow: hidden;
  transform: translateZ(0);
}

.strip-bottom,
.strip-top {
  position: absolute;
  right: 0;
  height: inherit;
  background-image: linear-gradient(to bottom, #3a9bc2, #0d4f6d);
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.5);
}

.strip-top {
  width: 180px;
  transform: skewX(20deg) translateX(50px);
}

.strip-bottom {
  width: 200px;
  transform: skewX(-15deg) translateX(50px);
}

.investor {
  position: relative;
  top: 20px;
  left: 30px;
  text-transform: uppercase;
}

.card-number {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 25px 10px;
  font-size: 23px;
}
